
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import Details from './ExtraworkListDetails.vue'
import Add from './ExtraworkListAdd.vue'

@KeepAlive
@Component({
  name: 'ExtraworkList',
  components: {
    Details, Add
  }
})
export default class ExtraworkList extends Vue {
  private searchInfo = {
    projectId: '',
    overtimeDate: '',
    teamName: ''
  }

  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private tableData = []

  private addShow = false

  private id = ''
  private detailsId = ''
  private detailsShow = false

  // private correctId = ''
  private correctShow = false

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  loadData () {
    // console.log('loadData')
    this.loading = true
    this.$axios.get(this.$apis.laborManage.selectOvertimeRecordByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.recruitUse.exportWorkerDutyCount, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  onDetail (id: any) {
    this.detailsId = id
    this.detailsShow = true
  }

  onEdit (id: any) {
    this.id = id
    this.addShow = true
  }

  // 新增
  onAdd () {
    console.log('新增')
    this.addShow = true
  }

  // 删除
  onDelete (overtimeId: any) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.laborManage.deleteYhOvertime, { overtimeId }).then(() => {
        // this.handleClose()
        this.$message.success('删除成功')
        this.loadData()
      })
    })
  }
}
