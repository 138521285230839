
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({
  name: 'WorkerListDetails'
})
export default class extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly correctShow!: boolean

  private loading = false
  private submitShow = false

  private rules= {
    signNum: [
      { required: true, message: '输入1-99数字', trigger: ['change', 'blur'], pattern: /^[1-9][0-9]{0,1}$/ }
    ]
  }

  private info = {
    yhTeamAttendance: {},
    onSignRecords: [],
    offSignRecords: [],
    overtimeRecords: []
  }

  @Watch('correctShow')
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    // this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhTeamAttendanceDetail, { attendanceId: this.id }).then(res => {
      if (res) {
        this.info = res
      }
    }).finally(() => {
      // this.loading = false
    })
  }

  saveForm () {
    (this.$refs.info as any).validate((valid: any) => {
      if (valid) {
        // console.log('通过校验')
        let params = [] as any
        params = [...this.info.onSignRecords, ...this.info.offSignRecords]
        params = params.map((item: any) => {
          return {
            signId: item.signId,
            signNum: item.signNum
          }
        })
        this.submitShow = true
        this.$axios.post(this.$apis.laborManage.updateTeamSignNum, params).then(() => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  imgSrc (val: any) {
    // console.log('图片处理', val)
    if (val) {
      if (val === 'Y') {
        return require('../../../assets/img/attendance/card_replacement.svg')
      } else {
        return require('../../../assets/img/attendance/sign.svg')
      }
    }
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:correctShow', false)
    this.info = {
      yhTeamAttendance: {},
      onSignRecords: [],
      offSignRecords: [],
      overtimeRecords: []
    }
  }
}
