
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'

@Component({
  name: 'WorkerListDetails',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop() readonly detailsId!: string
  @Prop() readonly detailsShow!: boolean

  private loading = false

  private info = {
    projectName: '',
    overtimeDate: '',
    workers: [],
    workersString: '',
    teamName: '',
    overtimeNum: '',
    signProjectName: '',
    remark: '',
    fileList: [],
    updateUserId: '',
    updateTime: ''
  }

  @Watch('detailsShow')
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    // this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhOvertimeRecordDetailByOvertimeId, { overtimeId: this.detailsId }).then(res => {
      // res.workersString
      let workersString = ''
      if (res.workers) {
        res.workers.forEach((item: any, index: any) => {
          if (index === res.workers.length - 1) {
            workersString = workersString + item.workerName + ''
          } else {
            workersString = workersString + item.workerName + '、'
          }
        })
      }
      res.workersString = workersString
      this.info = res
    }).finally(() => {
      // this.loading = false
    })
  }

  handleClose () {
    this.$emit('update:detailsShow', false)
    this.$emit('update:detailsId', '')
    this.info = {
      projectName: '',
      overtimeDate: '',
      workers: [],
      workersString: '',
      teamName: '',
      overtimeNum: '',
      signProjectName: '',
      remark: '',
      fileList: [],
      updateUserId: '',
      updateTime: ''
    }
  }
}
