
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import Details from './TeamsDetails.vue'
import Correct from './TeamsCorrect.vue'

@KeepAlive
@Component({
  name: 'TeamsList',
  components: {
    Details, Correct
  },
  filters: {
    getStatusName: function (value: any) {
      // 签到状态 1正常 2缺上班签到 3缺下班签到 4上下班人数不一致
      if (value) {
        if (value === '1') {
          return '正常'
        } else {
          return '异常'
        }
      }
    },
    getStatusName2: function (value: any) {
      if (value) {
        if (value === '1') {
          return '正常'
        } else if (value === '2') {
          return '缺上班签到'
        } else if (value === '3') {
          return '缺下班签到'
        } else if (value === '4') {
          return '上下班人数不一致'
        } else {
          return '--'
        }
      }
    }
  }
})
export default class MyOrder extends Vue {
  private searchInfo = {
    projectId: '',
    attendanceDate: '',
    teamName: ''
  }

  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private tableData = []

  private detailsId = ''
  private detailsShow = false

  private correctId = ''
  private correctShow = false

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  loadData () {
    // console.log('loadData')
    this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhTeamAttendanceByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.recruitUse.exportWorkerDutyCount, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  onDetail (id: any) {
    this.detailsId = id
    this.detailsShow = true
  }

  onCorrect (id: any) {
    this.correctId = id
    this.correctShow = true
  }

  goCorrect () {
    this.$router.push({
      name: 'correct'
    })
  }
}
