
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { FileInfo } from '@/types/uploadFile'

@Component({
  name: 'WorkerListDetails',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly detailsShow!: boolean

  private loading = false

  private info = {
    onSignRecords: [],
    offSignRecords: [],
    overtimeRecords: [],
    yhTeamAttendance: {}
  }

  @Watch('detailsShow')
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    // this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhTeamAttendanceDetail, { attendanceId: this.id }).then(res => {
      if (res) {
        this.info = res
      }
    }).finally(() => {
      // this.loading = false
    })
  }

  onSignNum () {
    let num = 0
    this.info.onSignRecords.forEach((item: any) => {
      num = num + item.signNum
    })
    return num
  }

  offSignNum () {
    let num = 0
    this.info.offSignRecords.forEach((item: any) => {
      num = num + item.signNum
    })
    return num
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:detailsShow', false)
    this.info = {
      onSignRecords: [],
      offSignRecords: [],
      overtimeRecords: [],
      yhTeamAttendance: {}
    }
  }

  imgSrc (val: any) {
    // console.log('图片处理', val)
    if (val) {
      if (val === 'Y') {
        return require('../../../assets/img/attendance/card_replacement.svg')
      } else {
        return require('../../../assets/img/attendance/sign.svg')
      }
    }
  }
}
