
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'AddBase',
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop() readonly addShow!: boolean
  @Prop() readonly id!: string

  private validate_overtimeNum = (rule: object, value: string, callback: Function) => {
    if (!/^[1-9]\d?$/g.test(value)) {
      callback(new Error('请输入1-99数字'))
    } else {
      callback()
    }
  }

  // 一位小数
  private oneDecimal = (rule: object, value: string, callback: Function) => {
    const reg = /^([1-9][0-9]*|[0-9])(\.\d)?$/
    if (value && !reg.test(value)) {
      return callback(new Error('请输入一位以内的小数'))
    } else {
      return callback()
    }
  }

  private validate_overtimeHours = (rule: object, value: any, callback: Function) => {
    const v = Number(value)
    if (v as any !== v) {
      callback(new Error('请输入数字'))
    } else if (v < 0.1 || value > 24) {
      callback(new Error('请输入0.1到24的数字'))
    } else {
      callback()
    }
  }

  private pickerOptions={
    disabledDate (time: any) {
      const now = (new Date()).getTime()
      const sevenAfter = 91 * 24 * 3600 * 1000
      const res = now - sevenAfter
      return time.getTime() < res || time.getTime() > now
    }
  }

  private title = '新增加班'

  private isEdit = false
  private info = {
    projectId: '', // 项目id
    overtimeType: '1', // 加班关联类型 1自建工人加班 2班组加班
    workers: [] as any, // 工人
    teamId: '' as any, // 班组id
    overtimeDate: '', // 加班日期
    overtimeNum: '', // 加班总人数
    overtimeHours: '', // 加班时长
    remark: '', // 加班原因
    fileList: [] // 附件列表
  }

  private userList = []
  private teamData = []
  private submitShow = false

  private rules= {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    overtimeDate: [
      { required: true, message: '请选择加班日期', trigger: 'change' }
    ],
    workers: [
      { required: true, message: '请选择工人信息', trigger: 'change' }
    ],
    teamId: [
      { required: true, message: '请选择班组名称', trigger: 'change' }
    ],
    overtimeNum: [
      { required: true, message: '请输入加班人数', trigger: 'change' },
      { validator: this.validate_overtimeNum, trigger: ['blur', 'change'] }
    ],
    overtimeHours: [
      { required: true, message: '请输入加班时长', trigger: 'change' },
      { validator: this.validate_overtimeHours, trigger: ['blur', 'change'] },
      { validator: this.oneDecimal }
    ],
    remark: [
      { required: true, message: '请输入加班说明', trigger: 'change' }
    ],
    fileList: [
      { required: true, message: '请选择加班图片', trigger: 'change' }
    ]
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  @Watch('info.projectId')
  projectId (val: string) {
    if (val) {
      this.getUserList(this.info.projectId)
      this.getTeamList(this.info.projectId)
    }
  }

  @Watch('id')
  isTrue (val: string) {
    // console.log('id', this.id)
    if (val) {
      this.getDetail()
      this.isEdit = true
      this.title = '编辑加班'
    } else {
      this.isEdit = false
      this.title = '新增加班'
    }
  }

  created () {
    // this.getTeamList() // 加载班组列表
  }

  getTeamList (id: any) {
    this.$axios.get(this.$apis.laborManage.selectYhTeamByPage, {
      projectId: id
    }).then(res => {
      // console.log('班组', res)
      res.list = res.list.filter((item: any) => {
        return item.status === '1'
      })
      this.teamData = res.list || []
    })
  }

  // 项目人员列表
  getUserList (id: any) {
    this.$axios.get(this.$apis.worker.selectYhWorkerByPage, {
      projectId: id
    }).then(res => {
      this.userList = res.list || []
    })
  }

  getDetail () {
    // console.log('111', this.id)
    this.$axios.get(this.$apis.laborManage.selectYhOvertimeRecordDetailByOvertimeId, { overtimeId: this.id }).then(reslult => {
      const res1 = this.$axios.get(this.$apis.worker.selectYhWorkerByPage, {
        projectId: reslult.projectId
      })
      const res2 = this.$axios.get(this.$apis.laborManage.selectYhTeamByPage, {
        projectId: reslult.projectId
      })
      Promise.all([res1, res2]).then((res) => {
        this.userList = res[0].list || []
        this.teamData = res[1].list || []
        if (reslult?.workers?.length) {
          reslult.workers = reslult.workers.map((item: any) => {
            return item.workerId
          })
        }
        // console.log('this.info', reslult)
        this.info = reslult
      })
    })
  }

  selectProject (val: any) {
    this.info.overtimeType = '1'
    this.info.workers = []
    this.info.teamId = ''
    this.info.overtimeDate = ''
    this.info.overtimeNum = ''
    this.info.overtimeHours = ''
    this.info.remark = ''
    this.info.fileList = []
  }

  selectOvertimeType (val: any) {
    // console.log('val', val)
    this.info.overtimeNum = ''
    if (val === '1') {
      this.info.teamId = ''
    } else {
      this.info.workers = []
    }
  }

  selectWorkers () {
    // console.log('info.workers', this.info.workers)
    this.info.overtimeNum = '' + this.info.workers.length
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this.info as any).fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  saveForm () {
    // 处理工人信息和班组,绕过校验
    if (this.info?.overtimeType === '1') {
      // console.log('选了工人')
      this.info.teamId = '$123'
    } else {
      this.info.workers = ['123']
    }
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        // 处理工人信息和班组数据,绕过校验后还原数据
        if (this.info?.overtimeType === '1') {
          this.info.teamId = ''
        } else {
          // console.log('工人')
          this.info.workers = []
        }

        const params = JSON.parse(JSON.stringify(this.info))
        // console.log('params', params)
        this.submitShow = true
        // 处理工人信息和班组数据
        if (params.overtimeType === '1') {
          delete params.teamId
          params.workers = params.workers.map((item: any) => {
            if (typeof item === 'string') {
              return {
                workerId: item
              }
            } else {
              return {
                workerId: item.workerId
              }
            }
          })
        } else {
          delete params.workers
        }

        if (this.id) {
          // 编辑
          this.$axios.post(this.$apis.laborManage.updateYhOvertime, params).then(() => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('success')
          }).finally(() => {
            this.submitShow = false
          })
        } else {
          // 新增
          this.$axios.post(this.$apis.laborManage.verifyYhOvertime, params).then((res) => {
            if (res.status === '1') {
              this.$confirm('该日期已有加班记录, 是否继续添加?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$axios.post(this.$apis.laborManage.insertYhOvertime, params).then(() => {
                  this.$message.success('保存成功')
                  this.handleClose()
                  this.$emit('success')
                }).finally(() => {
                  this.submitShow = false
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消添加'
                })
              })
            } else {
              this.$axios.post(this.$apis.laborManage.insertYhOvertime, params).then(() => {
                this.$message.success('保存成功')
                this.handleClose()
                this.$emit('success')
              }).finally(() => {
                this.submitShow = false
              })
            }
          }).finally(() => {
            this.submitShow = false
          })
        }
      } else {
        // 处理工人信息和班组数据,绕过校验后还原数据
        if (this.info?.overtimeType === '1') {
          this.info.teamId = ''
        } else {
          this.info.workers = []
        }
      }
    })
  }

  handleClose () {
    this.$emit('update:addShow', false)
    this.$emit('update:id', '')
    this.info = {
      projectId: '', // 项目id
      overtimeType: '1', // 加班关联类型 1自建工人加班 2班组加班
      workers: [], // 工人
      teamId: '', // 班组id
      overtimeDate: '', // 加班日期
      overtimeNum: '', // 加班总人数
      overtimeHours: '', // 加班时长
      remark: '', // 加班原因
      fileList: [] // 附件列表
    }
    this.userList = []
    this.teamData = [];
    (this.$refs.info as ElForm).resetFields()
  }
}
