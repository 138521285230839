
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import WorkerList from './WorkerList.vue'
import TeamsList from './TeamsList.vue'
import ExtraworkList from './ExtraworkList.vue'
@KeepAlive
@Component({
  name: 'laborManage',
  components: {
    WorkerList, TeamsList, ExtraworkList
  }
})
export default class MyOrder extends Vue {
  private activeName ='0'
  private componentName ='WorkerList'

  changeComponentName (val: any) {
    if (val === '0') {
      this.componentName = 'WorkerList'
    } else if (val === '1') {
      this.componentName = 'TeamsList'
    } else {
      this.componentName = 'ExtraworkList'
    }
  }
}
